export const environment = {
  production: true,
  env: 'dev',
  host: 'https://dso-api-dev.china.thoughtworks.net',
  authConfig: {
    issuer: 'https://dev-66176561.okta.com/oauth2/default',
    redirectUri: 'https://dso-web-dev.china.thoughtworks.net/implicit/callback',
    clientId: '0oann41ak1mF4sihz5d7',
    pkce: true,
  },
  salesFunnel: 'https://uat.sales-funnel.thoughtworks.net/opportunity-details/',
  wsEndpoint: 'wss://dso-api-dev.china.thoughtworks.net',
  matomoConfig: {
    url: 'https://thoughtworks.innocraft.cloud/',
    id: 60,
  },
};
